import { SetStateAction, useEffect, useState } from 'react';
import { Button, message, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { setupAxiosByToken } from '../../../../request';
import { actions } from '../../../redux/auth/authRedux';
import { VerifyOTPAction } from '../../../redux/verifyOTP';
import { checkValueIsPhoneNumber } from '../../../Utilities/validateText';
import {
  requestChangeEmail,
  requestChangePassword,
  requestPassword,
  verifyOTP,
  verifyOTPChangeEmail,
  verifyOTPregister,
  verifyRegister
} from '../../../redux/auth/authCrud';
import {
  AuthPageType,
  AxiosError,
  ClientInfo,
  LoginResponse,
  OtpPassword,
  OtpRegister
} from '../../../../interface';
import dayjs from 'dayjs';
import OtpInput from 'react-otp-input';
import './index.scss';

interface VerifyOTPProps {
  clientInfo?: ClientInfo;
  currentLocation?: GeolocationCoordinates;
  pageType: AuthPageType;
  otp: OtpRegister | OtpPassword;
  setPageType: React.Dispatch<SetStateAction<AuthPageType>>;
  setOTPData: React.Dispatch<
    SetStateAction<OtpPassword | OtpRegister | undefined>
  >;
  setUserTokenRegistered?: React.Dispatch<
    SetStateAction<LoginResponse | undefined>
  >;
  onBack: () => void;
  onClose: () => void;
}

const VerifyOTP = (props: VerifyOTPProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const regexInput = RegExp(/^[0-9]+$/i);
  const [otpCode, setOTPCode] = useState<string>('');
  const [timeCounter, setTimeCounter] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>();

  const { clientInfo } = useSelector(
    (state: HibraryRootState) => ({
      clientInfo: state.clientInfo,
      forgotPassword: state.verifyOTP
    }),
    shallowEqual
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = (props.otp?.expireDate ?? 0) - dayjs().unix();
      if (timeLeft <= 0) {
        clearInterval(interval);
        setTimeCounter(timeLeft);
      } else {
        setTimeCounter(timeLeft);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timeCounter, props.otp]);

  useEffect(() => {
    if (status) setStatus(undefined);
  }, [otpCode]);

  const onOTPChange = (e: any) => {
    const value = e;
    if (value && value.length <= 6 && regexInput.test(value)) {
      setOTPCode(value);
    } else if (value === '') {
      setOTPCode(value);
    }
  };

  const renderExpireTime = () => {
    if (timeCounter) {
      const minutes =
        timeCounter > 60
          ? Math.floor(timeCounter / 60) +
            ' ' +
            intl.formatMessage({
              id: 'Unit.Minutes'
            })
          : '';
      const seconds =
        (timeCounter % 60) + ` ${intl.formatMessage({ id: 'Unit.Seconds' })}`;
      return `${minutes} ${seconds}`;
    }
  };

  const onOTPSubmitWrongMoreThanLimit = () => {
    dispatch(actions.logout());
    props.onClose();

    Modal.error({
      content: (
        <div className="OTP-wrong-content">
          <img src="/images/OTPWrong.gif" className="otp-img-alert" />
          <div className="text-alert">
            <h2>บัญชีของคุณถูกระงับการใช้งาน</h2>
            <p>เนื่องจากมีการพยายามกรอกรหัส OTP มากกว่ากำหนด</p>
          </div>
        </div>
      ),
      okText: 'ตกลง',
      className: 'OTP-wrong'
    });
  };

  const onSendOTP = async () => {
    switch (props.pageType) {
      case AuthPageType.RegisterOTP: {
        try {
          setLoading(true);
          const otp = props.otp as OtpRegister;
          const response = await verifyOTPregister(
            clientInfo.companyPrefix,
            otp.userID,
            otp.referenceCode,
            otpCode
          );
          if (response) {
            dispatch(
              actions.setUserInfoForRegister(
                Object.assign(
                  {
                    name: response.name ?? '',
                    surname: response.surname ?? ''
                  },
                  response
                )
              )
            );
            props.setOTPData({ ...otp, verifyOtp: otpCode });
            props.setPageType(AuthPageType.RegisterForm);
          }
        } catch (error) {
          const getError = error as AxiosError;
          setOTPCode('');
          setStatus(getError.response.data.message);
          if (getError.response.data.errorCode === '940') {
            onOTPSubmitWrongMoreThanLimit();
          }
        } finally {
          setLoading(false);
        }
        break;
      }
      case AuthPageType.ChangePasswordOTP:
      case AuthPageType.ForgotPasswordOTP: {
        try {
          setLoading(true);
          const otp = props.otp as OtpPassword;
          const response = await verifyOTP(
            otp.uid!,
            otpCode,
            otp.referenceCode!
          );
          if (response) {
            props.setOTPData({ ...otp, verifyOtp: otpCode });
            props.setPageType(AuthPageType.NewPassword);
            setupAxiosByToken(response?.userToken);
            if (props.setUserTokenRegistered)
              props.setUserTokenRegistered(response);
          }
        } catch (error) {
          const getError = error as AxiosError;
          setOTPCode('');
          setStatus(getError.response.data.message);
          if (getError.response.data.errorCode === '940') {
            onOTPSubmitWrongMoreThanLimit();
          }
        } finally {
          setLoading(false);
        }
        break;
      }
      case AuthPageType.ChangeEmailOTP: {
        try {
          setLoading(true);
          const otp = props.otp as OtpRegister;
          const response = await verifyOTPChangeEmail(
            otp.uid,
            otpCode,
            otp.referenceCode
          );
          if (response) {
            props.setOTPData({ ...otp, verifyOtp: otpCode });
            props.setPageType(AuthPageType.SuccessChangeEmail);
            if (props.setUserTokenRegistered)
              props.setUserTokenRegistered(response);
            setupAxiosByToken(response?.userToken);
          }
        } catch (error) {
          const getError = error as AxiosError;
          setOTPCode('');
          setStatus(getError.response.data.message);
        } finally {
          setLoading(false);
        }
        break;
      }
    }
  };

  const requestNewOTP = async () => {
    const key = 'fetchNewOTP';
    switch (props.pageType) {
      case AuthPageType.RegisterOTP: {
        try {
          const otp = props.otp as OtpRegister;
          message.loading({ content: 'Request OTP...', key });
          const response = await verifyRegister(
            props.clientInfo?.companyPrefix,
            otp.userID ?? otp.email,
            props.currentLocation
              ? {
                  latitude: props.currentLocation.latitude,
                  longitude: props.currentLocation.longitude
                }
              : undefined
          );
          if (response) {
            props.setOTPData(response);
            dispatch(VerifyOTPAction.initRegisterOTP(response));
          }
        } finally {
          message.success({ content: 'Send  New OTP!', key });
        }
        break;
      }
      case AuthPageType.ChangeEmailOTP: {
        try {
          const otp = props.otp as OtpPassword;
          message.loading({ content: 'Request OTP...', key });
          const response = await requestChangeEmail(otp.userID ?? '');
          if (response) {
            props.setOTPData(response);
            props.setPageType(AuthPageType.ChangeEmailOTP);
          }
        } finally {
          message.success({ content: 'Send  New OTP!', key });
        }
        break;
      }
      case AuthPageType.ForgotPasswordOTP: {
        try {
          const otp = props.otp as OtpPassword;
          message.loading({ content: 'Request OTP...', key });
          const response = await requestPassword(otp.userID ?? '');
          if (response) {
            props.setOTPData(response);
            if (checkValueIsPhoneNumber(otp.userID ?? ''))
              dispatch(VerifyOTPAction.intiForgotPasswordOTP(response));
          }
        } finally {
          message.success({ content: 'Send  New OTP!', key });
        }
        break;
      }
      case AuthPageType.ChangePasswordOTP: {
        try {
          const otp = props.otp as OtpPassword;
          message.loading({ content: 'Request OTP...', key });
          const response = await requestChangePassword();
          if (response) {
            props.setOTPData(response);
            if (checkValueIsPhoneNumber(otp.userID ?? ''))
              dispatch(VerifyOTPAction.initChangePasswordOTP(response));
          }
        } finally {
          message.success({ content: 'Send New OTP!', key });
        }
      }
    }
  };

  return (
    <div className="verifyOTPContainer transition-animate">
      <h3>Verify OTP</h3>
      <p className="referenceCode">
        {intl.formatMessage({ id: 'VerifyRegister.ReferenceCode' }) +
          ' : ' +
          props.otp?.referenceCode}
      </p>
      <div>
        <OtpInput
          value={otpCode}
          onChange={onOTPChange}
          numInputs={6}
          className="otpInput"
          isInputNum
        />
      </div>
      {timeCounter && timeCounter > 0 ? (
        <p className="expireTime">
          {intl.formatMessage(
            { id: 'VerifyOTP.TimeCounter' },
            { 0: renderExpireTime() }
          )}
        </p>
      ) : (
        <p className="requestOTP" onClick={() => requestNewOTP()}>
          ขอรหัส OTP อีกครั้ง
        </p>
      )}
      <div className="errorField">{status && <div>{status}</div>}</div>
      <div className="buttonContain">
        <Button
          loading={loading}
          onClick={() => onSendOTP()}
          disabled={loading || otpCode.length < 6}
          className="acceptButton"
        >
          {loading
            ? intl.formatMessage({ id: 'Action.WaitAMomentBtn' })
            : intl.formatMessage({ id: 'Action.ConfirmBtn' })}
        </Button>
        <span className="back" onClick={props.onBack}>
          {intl.formatMessage({ id: 'Action.Back' })}
        </span>
      </div>
    </div>
  );
};

export default VerifyOTP;
