import axios from 'axios';
import { HtiResponse } from '../../../interface/request';
import { ContentType, LicenseType } from '../../../interface';

export const fetchAutocomplete = async (
  keyword: string,
  clientPrefix: string,
  isLogin: boolean
) => {
  const url = isLogin
    ? `/store/autoComplete?keyword=${keyword.trim().toLowerCase()}`
    : `/public/${clientPrefix}/store/autoComplete?keyword=${keyword
        .trim()
        .toLowerCase()}`;
  const {
    data: { data }
  } = await axios.get<HtiResponse<AutoCompleteResult>>(url);
  return data;
};
export const saveSearchAutoComplete = async (keyword: string) => {
  const {
    data: { status }
  } = await axios.post<HtiResponse<saveSearchAutoCompleteResult>>(
    `/statistics/search?keyword=${keyword}`
  );

  return status;
};
export const saveSearchAutoCompletePublic = async (
  keyword: string,
  clientPrefix: string
) => {
  const {
    data: { status }
  } = await axios.post<HtiResponse<saveSearchAutoCompleteResult>>(
    `/public/${clientPrefix}/search?keyword=${keyword}`
  );

  return status;
};
// statistics/search
export type AutoCompleteKey =
  | KeywordResult[]
  | AuthorResult[]
  | PublisherResult[]
  | TitleResult[]
  | CategoryResult[];
export interface AutoCompleteResult {
  author: AuthorResult[];
  publisher: PublisherResult[];
  keyword: KeywordResult[];
}
export interface saveSearchAutoCompleteResult {
  status: boolean;
}
export interface KeywordResult {
  contentUID: string;
  keyword: string;
  type: SearchType;
}
export interface AuthorResult {
  creatorUID: string;
  keyword: string;
  type: SearchType;
  licenseType: LicenseType;
}

export interface PublisherResult {
  creatorUID: string;
  keyword: string;
  type: SearchType;
}

export interface TitleResult {
  contentUID: string;
  keyword: string;
  type: SearchType;
  contentType: ContentType;
}

export interface CategoryResult {
  catId: number;
  subcatId: number;
  catName: string;
  subcatName: string;
  type: SearchType;
  licenseType: LicenseType;
}

export enum SearchType {
  TITLE = 'TITLE',
  PUBLISHER = 'PUBLISHER',
  AUTHOR = 'AUTHOR',
  KEYWORD = 'KEYWORD',
  CATEGORY = 'CATEGORY'
}
